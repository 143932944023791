
import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="South Island - New Zealand | Kiwi & Cacahuate" description="Our final trip to New Zealand before we moved back to Mexico" url="https://kiwicacahuate.com/south-island/" image="https://kiwicacahuate.com/south-island/south-island.jpg" imageWidth="4000" imageHeight="2668" />

    <div className="">
      <div className="story">
        <div className="photoset-section">

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="030">
                <source srcSet="/south-island/south-island.webp, /south-island/south-island.webp 2x" />
                <source srcSet="/south-island/south-island.jpg, /south-island/south-island.jpg 2x" />
                <img src="/south-island/south-island.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">South Island - New Zealand</h2>
            <div className="photoset-intro left-align">
              <p>Road tripping through New Zealand’s South island was a literal dream. Even during the hard moments (that emerge in every trip), the landscapes were breathtaking and taken-out from a movie. Should you go to NZ? I will not answer this as the GF of a Kiwi, but as Mexican girl that loves traveling: If you can do it, you have yo visit this place at one point of your life. We hope the pictures say more than anything we could. Sure, they will never be close to the real thing(s), still, enjoy!</p>
              <p className="no-margin">
                Here’s a list of the places that you’ll see featured in these pictures (except for the glowworm caves because photos weren’t allowed):
                <ul>
                  <li>Lake Tekapo</li>
                  <li>Mount Cook / Aoraki (tallest peak in NZ)</li>
                  <li>Aaoraki National Park</li>
                  <li>Lake Pukaki</li>
                  <li>Twizel</li>
                  <li>Wanaka</li>
                  <li>Hawea</li>
                  <li>Blue Pools</li>
                  <li>Te Anau</li>
                  <li>Te Anau glowworm cave</li>
                  <li>Milford Sound</li>
                  <li>Doubtful Sound (in Fiorland)</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">

              <picture data-name="001">
                <source srcSet="/south-island/thumbnail/001.webp, /south-island/thumbnail/001-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/001.jpg, /south-island/thumbnail/001-2x.jpg 2x" />
                <img src="/south-island/thumbnail/001.jpg" alt="Kiwi cachuate images" />
              </picture>

            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="002">
                <source srcSet="/south-island/thumbnail/002.webp, /south-island/thumbnail/002-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/002.jpg, /south-island/thumbnail/002-2x.jpg 2x" />
                <img src="/south-island/thumbnail/002.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="003">
                <source srcSet="/south-island/thumbnail/003.webp, /south-island/thumbnail/003-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/003.jpg, /south-island/thumbnail/003-2x.jpg 2x" />
                <img src="/south-island/thumbnail/003.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="004">
                <source srcSet="/south-island/thumbnail/004.webp, /south-island/thumbnail/004-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/004.jpg, /south-island/thumbnail/004-2x.jpg 2x" />
                <img src="/south-island/thumbnail/004.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="005">
                <source srcSet="/south-island/thumbnail/005.webp, /south-island/thumbnail/005-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/005.jpg, /south-island/thumbnail/005-2x.jpg 2x" />
                <img src="/south-island/thumbnail/005.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="006">
                <source srcSet="/south-island/thumbnail/006.webp, /south-island/thumbnail/006-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/006.jpg, /south-island/thumbnail/006-2x.jpg 2x" />
                <img src="/south-island/thumbnail/006.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="007">
                <source srcSet="/south-island/thumbnail/007.webp, /south-island/thumbnail/007-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/007.jpg, /south-island/thumbnail/007-2x.jpg 2x" />
                <img src="/south-island/thumbnail/007.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="008">
                <source srcSet="/south-island/thumbnail/008.webp, /south-island/thumbnail/008-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/008.jpg, /south-island/thumbnail/008-2x.jpg 2x" />
                <img src="/south-island/thumbnail/008.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="010">
                <source srcSet="/south-island/thumbnail/010.webp, /south-island/thumbnail/010-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/010.jpg, /south-island/thumbnail/010-2x.jpg 2x" />
                <img src="/south-island/thumbnail/010.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="009">
                <source srcSet="/south-island/thumbnail/009.webp, /south-island/thumbnail/009-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/009.jpg, /south-island/thumbnail/009-2x.jpg 2x" />
                <img src="/south-island/thumbnail/009.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="012">
                <source srcSet="/south-island/thumbnail/012.webp, /south-island/thumbnail/012-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/012.jpg, /south-island/thumbnail/012-2x.jpg 2x" />
                <img src="/south-island/thumbnail/012.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="013">
                <source srcSet="/south-island/thumbnail/013.webp, /south-island/thumbnail/013-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/013.jpg, /south-island/thumbnail/013-2x.jpg 2x" />
                <img src="/south-island/thumbnail/013.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="014">
                <source srcSet="/south-island/thumbnail/014.webp, /south-island/thumbnail/014-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/014.jpg, /south-island/thumbnail/014-2x.jpg 2x" />
                <img src="/south-island/thumbnail/014.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="015">
                <source srcSet="/south-island/thumbnail/015.webp, /south-island/thumbnail/015-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/015.jpg, /south-island/thumbnail/015-2x.jpg 2x" />
                <img src="/south-island/thumbnail/015.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="016">
                <source srcSet="/south-island/thumbnail/016.webp, /south-island/thumbnail/016-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/016.jpg, /south-island/thumbnail/016-2x.jpg 2x" />
                <img src="/south-island/thumbnail/016.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="017">
                <source srcSet="/south-island/thumbnail/017.webp, /south-island/thumbnail/017-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/017.jpg, /south-island/thumbnail/017-2x.jpg 2x" />
                <img src="/south-island/thumbnail/017.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="018">
                <source srcSet="/south-island/thumbnail/018.webp, /south-island/thumbnail/018-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/018.jpg, /south-island/thumbnail/018-2x.jpg 2x" />
                <img src="/south-island/thumbnail/018.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="019">
                <source srcSet="/south-island/thumbnail/019.webp, /south-island/thumbnail/019-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/019.jpg, /south-island/thumbnail/019-2x.jpg 2x" />
                <img src="/south-island/thumbnail/019.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="020">
                <source srcSet="/south-island/thumbnail/020.webp, /south-island/thumbnail/020-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/020.jpg, /south-island/thumbnail/020-2x.jpg 2x" />
                <img src="/south-island/thumbnail/020.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="021">
                <source srcSet="/south-island/thumbnail/021.webp, /south-island/thumbnail/021-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/021.jpg, /south-island/thumbnail/021-2x.jpg 2x" />
                <img src="/south-island/thumbnail/021.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="022">
                <source srcSet="/south-island/thumbnail/022.webp, /south-island/thumbnail/022-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/022.jpg, /south-island/thumbnail/022-2x.jpg 2x" />
                <img src="/south-island/thumbnail/022.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="023">
                <source srcSet="/south-island/thumbnail/023.webp, /south-island/thumbnail/023-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/023.jpg, /south-island/thumbnail/023-2x.jpg 2x" />
                <img src="/south-island/thumbnail/023.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="024">
                <source srcSet="/south-island/thumbnail/024.webp, /south-island/thumbnail/024-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/024.jpg, /south-island/thumbnail/024-2x.jpg 2x" />
                <img src="/south-island/thumbnail/024.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="025">
                <source srcSet="/south-island/thumbnail/025.webp, /south-island/thumbnail/025-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/025.jpg, /south-island/thumbnail/025-2x.jpg 2x" />
                <img src="/south-island/thumbnail/025.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="026">
                <source srcSet="/south-island/thumbnail/026.webp, /south-island/thumbnail/026-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/026.jpg, /south-island/thumbnail/026-2x.jpg 2x" />
                <img src="/south-island/thumbnail/026.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="027">
                <source srcSet="/south-island/thumbnail/027.webp, /south-island/thumbnail/027-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/027.jpg, /south-island/thumbnail/027-2x.jpg 2x" />
                <img src="/south-island/thumbnail/027.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="028">
                <source srcSet="/south-island/thumbnail/028.webp, /south-island/thumbnail/028-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/028.jpg, /south-island/thumbnail/028-2x.jpg 2x" />
                <img src="/south-island/thumbnail/028.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="029">
                <source srcSet="/south-island/thumbnail/029.webp, /south-island/thumbnail/029-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/029.jpg, /south-island/thumbnail/029-2x.jpg 2x" />
                <img src="/south-island/thumbnail/029.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="030">
                <source srcSet="/south-island/thumbnail/030.webp, /south-island/thumbnail/030-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/030.jpg, /south-island/thumbnail/030-2x.jpg 2x" />
                <img src="/south-island/thumbnail/030.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="031">
                <source srcSet="/south-island/thumbnail/031.webp, /south-island/thumbnail/031-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/031.jpg, /south-island/thumbnail/031-2x.jpg 2x" />
                <img src="/south-island/thumbnail/031.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="032">
                <source srcSet="/south-island/thumbnail/032.webp, /south-island/thumbnail/032-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/032.jpg, /south-island/thumbnail/032-2x.jpg 2x" />
                <img src="/south-island/thumbnail/032.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="033">
                <source srcSet="/south-island/thumbnail/033.webp, /south-island/thumbnail/033-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/033.jpg, /south-island/thumbnail/033-2x.jpg 2x" />
                <img src="/south-island/thumbnail/033.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="034">
                <source srcSet="/south-island/thumbnail/034.webp, /south-island/thumbnail/034-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/034.jpg, /south-island/thumbnail/034-2x.jpg 2x" />
                <img src="/south-island/thumbnail/034.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="035">
                <source srcSet="/south-island/thumbnail/035.webp, /south-island/thumbnail/035-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/035.jpg, /south-island/thumbnail/035-2x.jpg 2x" />
                <img src="/south-island/thumbnail/035.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="036">
                <source srcSet="/south-island/thumbnail/036.webp, /south-island/thumbnail/036-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/036.jpg, /south-island/thumbnail/036-2x.jpg 2x" />
                <img src="/south-island/thumbnail/036.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="037">
                <source srcSet="/south-island/thumbnail/037.webp, /south-island/thumbnail/037-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/037.jpg, /south-island/thumbnail/037-2x.jpg 2x" />
                <img src="/south-island/thumbnail/037.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="038">
                <source srcSet="/south-island/thumbnail/038.webp, /south-island/thumbnail/038-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/038.jpg, /south-island/thumbnail/038-2x.jpg 2x" />
                <img src="/south-island/thumbnail/038.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="039">
                <source srcSet="/south-island/thumbnail/039.webp, /south-island/thumbnail/039-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/039.jpg, /south-island/thumbnail/039-2x.jpg 2x" />
                <img src="/south-island/thumbnail/039.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="040">
                <source srcSet="/south-island/thumbnail/040.webp, /south-island/thumbnail/040-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/040.jpg, /south-island/thumbnail/040-2x.jpg 2x" />
                <img src="/south-island/thumbnail/040.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="041">
                <source srcSet="/south-island/thumbnail/041.webp, /south-island/thumbnail/041-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/041.jpg, /south-island/thumbnail/041-2x.jpg 2x" />
                <img src="/south-island/thumbnail/041.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="042">
                <source srcSet="/south-island/thumbnail/042.webp, /south-island/thumbnail/042-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/042.jpg, /south-island/thumbnail/042-2x.jpg 2x" />
                <img src="/south-island/thumbnail/042.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="043">
                <source srcSet="/south-island/thumbnail/043.webp, /south-island/thumbnail/043-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/043.jpg, /south-island/thumbnail/043-2x.jpg 2x" />
                <img src="/south-island/thumbnail/043.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="044">
                <source srcSet="/south-island/thumbnail/044.webp, /south-island/thumbnail/044-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/044.jpg, /south-island/thumbnail/044-2x.jpg 2x" />
                <img src="/south-island/thumbnail/044.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="045">
                <source srcSet="/south-island/thumbnail/045.webp, /south-island/thumbnail/045-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/045.jpg, /south-island/thumbnail/045-2x.jpg 2x" />
                <img src="/south-island/thumbnail/045.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="046">
                <source srcSet="/south-island/thumbnail/046.webp, /south-island/thumbnail/046-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/046.jpg, /south-island/thumbnail/046-2x.jpg 2x" />
                <img src="/south-island/thumbnail/046.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="047">
                <source srcSet="/south-island/thumbnail/047.webp, /south-island/thumbnail/047-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/047.jpg, /south-island/thumbnail/047-2x.jpg 2x" />
                <img src="/south-island/thumbnail/047.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="048">
                <source srcSet="/south-island/thumbnail/048.webp, /south-island/thumbnail/048-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/048.jpg, /south-island/thumbnail/048-2x.jpg 2x" />
                <img src="/south-island/thumbnail/048.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item five">
              <picture data-name="082">
                <source srcSet="/south-island/thumbnail/082.webp, /south-island/thumbnail/082-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/082.jpg, /south-island/thumbnail/082-2x.jpg 2x" />
                <img src="/south-island/thumbnail/082.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
            <div className="photoset-item five">
              <picture data-name="083">
                <source srcSet="/south-island/thumbnail/083.webp, /south-island/thumbnail/083-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/083.jpg, /south-island/thumbnail/083-2x.jpg 2x" />
                <img src="/south-island/thumbnail/083.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="084">
                <source srcSet="/south-island/thumbnail/084.webp, /south-island/thumbnail/084-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/084.jpg, /south-island/thumbnail/084-2x.jpg 2x" />
                <img src="/south-island/thumbnail/084.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten loaded" style={{ maxWidth: 1920 }}>
              <video id="video" muted="true" loop="true" preload="true" autoPlay>
                <source src="/south-island/original/hoz_jet_boat.mp4" type="video/mp4" />
                "Your browser does not support video streaming via HTML5."
              </video>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="049">
                <source srcSet="/south-island/thumbnail/049.webp, /south-island/thumbnail/049-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/049.jpg, /south-island/thumbnail/049-2x.jpg 2x" />
                <img src="/south-island/thumbnail/049.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="050">
                <source srcSet="/south-island/thumbnail/050.webp, /south-island/thumbnail/050-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/050.jpg, /south-island/thumbnail/050-2x.jpg 2x" />
                <img src="/south-island/thumbnail/050.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="051">
                <source srcSet="/south-island/thumbnail/051.webp, /south-island/thumbnail/051-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/051.jpg, /south-island/thumbnail/051-2x.jpg 2x" />
                <img src="/south-island/thumbnail/051.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="052">
                <source srcSet="/south-island/thumbnail/052.webp, /south-island/thumbnail/052-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/052.jpg, /south-island/thumbnail/052-2x.jpg 2x" />
                <img src="/south-island/thumbnail/052.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="053">
                <source srcSet="/south-island/thumbnail/053.webp, /south-island/thumbnail/053-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/053.jpg, /south-island/thumbnail/053-2x.jpg 2x" />
                <img src="/south-island/thumbnail/053.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="054">
                <source srcSet="/south-island/thumbnail/054.webp, /south-island/thumbnail/054-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/054.jpg, /south-island/thumbnail/054-2x.jpg 2x" />
                <img src="/south-island/thumbnail/054.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="055">
                <source srcSet="/south-island/thumbnail/055.webp, /south-island/thumbnail/055-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/055.jpg, /south-island/thumbnail/055-2x.jpg 2x" />
                <img src="/south-island/thumbnail/055.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="056">
                <source srcSet="/south-island/thumbnail/056.webp, /south-island/thumbnail/056-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/056.jpg, /south-island/thumbnail/056-2x.jpg 2x" />
                <img src="/south-island/thumbnail/056.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="057">
                <source srcSet="/south-island/thumbnail/057.webp, /south-island/thumbnail/057-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/057.jpg, /south-island/thumbnail/057-2x.jpg 2x" />
                <img src="/south-island/thumbnail/057.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="058">
                <source srcSet="/south-island/thumbnail/058.webp, /south-island/thumbnail/058-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/058.jpg, /south-island/thumbnail/058-2x.jpg 2x" />
                <img src="/south-island/thumbnail/058.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="059">
                <source srcSet="/south-island/thumbnail/059.webp, /south-island/thumbnail/059-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/059.jpg, /south-island/thumbnail/059-2x.jpg 2x" />
                <img src="/south-island/thumbnail/059.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="060">
                <source srcSet="/south-island/thumbnail/060.webp, /south-island/thumbnail/060-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/060.jpg, /south-island/thumbnail/060-2x.jpg 2x" />
                <img src="/south-island/thumbnail/060.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="061">
                <source srcSet="/south-island/thumbnail/061.webp, /south-island/thumbnail/061-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/061.jpg, /south-island/thumbnail/061-2x.jpg 2x" />
                <img src="/south-island/thumbnail/061.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="062">
                <source srcSet="/south-island/thumbnail/062.webp, /south-island/thumbnail/062-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/062.jpg, /south-island/thumbnail/062-2x.jpg 2x" />
                <img src="/south-island/thumbnail/062.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="063">
                <source srcSet="/south-island/thumbnail/063.webp, /south-island/thumbnail/063-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/063.jpg, /south-island/thumbnail/063-2x.jpg 2x" />
                <img src="/south-island/thumbnail/063.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="064">
                <source srcSet="/south-island/thumbnail/064.webp, /south-island/thumbnail/064-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/064.jpg, /south-island/thumbnail/064-2x.jpg 2x" />
                <img src="/south-island/thumbnail/064.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="065">
                <source srcSet="/south-island/thumbnail/065.webp, /south-island/thumbnail/065-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/065.jpg, /south-island/thumbnail/065-2x.jpg 2x" />
                <img src="/south-island/thumbnail/065.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="066">
                <source srcSet="/south-island/thumbnail/066.webp, /south-island/thumbnail/066-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/066.jpg, /south-island/thumbnail/066-2x.jpg 2x" />
                <img src="/south-island/thumbnail/066.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="067">
                <source srcSet="/south-island/thumbnail/067.webp, /south-island/thumbnail/067-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/067.jpg, /south-island/thumbnail/067-2x.jpg 2x" />
                <img src="/south-island/thumbnail/067.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="068">
                <source srcSet="/south-island/thumbnail/068.webp, /south-island/thumbnail/068-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/068.jpg, /south-island/thumbnail/068-2x.jpg 2x" />
                <img src="/south-island/thumbnail/068.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="069">
                <source srcSet="/south-island/thumbnail/069.webp, /south-island/thumbnail/069-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/069.jpg, /south-island/thumbnail/069-2x.jpg 2x" />
                <img src="/south-island/thumbnail/069.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="070">
                <source srcSet="/south-island/thumbnail/070.webp, /south-island/thumbnail/070-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/070.jpg, /south-island/thumbnail/070-2x.jpg 2x" />
                <img src="/south-island/thumbnail/070.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="085">
                <source srcSet="/south-island/thumbnail/085.webp, /south-island/thumbnail/085-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/085.jpg, /south-island/thumbnail/085-2x.jpg 2x" />
                <img src="/south-island/thumbnail/085.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="071">
                <source srcSet="/south-island/thumbnail/071.webp, /south-island/thumbnail/071-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/071.jpg, /south-island/thumbnail/071-2x.jpg 2x" />
                <img src="/south-island/thumbnail/071.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="072">
                <source srcSet="/south-island/thumbnail/072.webp, /south-island/thumbnail/072-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/072.jpg, /south-island/thumbnail/072-2x.jpg 2x" />
                <img src="/south-island/thumbnail/072.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="073">
                <source srcSet="/south-island/thumbnail/073.webp, /south-island/thumbnail/073-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/073.jpg, /south-island/thumbnail/073-2x.jpg 2x" />
                <img src="/south-island/thumbnail/073.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="074">
                <source srcSet="/south-island/thumbnail/074.webp, /south-island/thumbnail/074-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/074.jpg, /south-island/thumbnail/074-2x.jpg 2x" />
                <img src="/south-island/thumbnail/074.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="075">
                <source srcSet="/south-island/thumbnail/075.webp, /south-island/thumbnail/075-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/075.jpg, /south-island/thumbnail/075-2x.jpg 2x" />
                <img src="/south-island/thumbnail/075.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="076">
                <source srcSet="/south-island/thumbnail/076.webp, /south-island/thumbnail/076-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/076.jpg, /south-island/thumbnail/076-2x.jpg 2x" />
                <img src="/south-island/thumbnail/076.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="077">
                <source srcSet="/south-island/thumbnail/077.webp, /south-island/thumbnail/077-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/077.jpg, /south-island/thumbnail/077-2x.jpg 2x" />
                <img src="/south-island/thumbnail/077.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="078">
                <source srcSet="/south-island/thumbnail/078.webp, /south-island/thumbnail/078-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/078.jpg, /south-island/thumbnail/078-2x.jpg 2x" />
                <img src="/south-island/thumbnail/078.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="079">
                <source srcSet="/south-island/thumbnail/079.webp, /south-island/thumbnail/079-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/079.jpg, /south-island/thumbnail/079-2x.jpg 2x" />
                <img src="/south-island/thumbnail/079.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="080">
                <source srcSet="/south-island/thumbnail/080.webp, /south-island/thumbnail/080-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/080.jpg, /south-island/thumbnail/080-2x.jpg 2x" />
                <img src="/south-island/thumbnail/080.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="081">
                <source srcSet="/south-island/thumbnail/081.webp, /south-island/thumbnail/081-2x.webp 2x" />
                <source srcSet="/south-island/thumbnail/081.jpg, /south-island/thumbnail/081-2x.jpg 2x" />
                <img src="/south-island/thumbnail/081.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

